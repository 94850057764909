<template>
  <div class="page cateogry-page">
    <Header/>
    <HotlineMobileCard/>
    <div class="container">
      <el-carousel height="auto" class="mb--sm--3 mb--md--9">
        <el-carousel-item class="swiper-slide" v-for="(banner, index) in banners" :key="index"><img :src="banner.url" :alt="banner.name" style="width: 100%; height: auto"></el-carousel-item>
      </el-carousel>
      <ChooseCustomer label="Chọn khách hàng để đặt hàng" buttonText="Đặt Hàng"/>
      <div class="h5 font-weight--500 mb--sm--3 mb--md--6">Nhóm điều trị</div>
      <div class="row category__list">
        <div class="col col--sm--4 col--md--3 col--lg--2" v-for="group in groupProduct" :key="group.groupCode">
          <div class="category__item" v-on:click="$router.push('category/' + group.groupCode).catch(()=>{})">
          <object style="width: 100%" :data="group.icon"></object>
          </div>
        </div>
        </div>
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FooterMobile from '@/components/FooterMobile.vue'
import HotlineMobileCard from '@/components/HotlineMobileCard.vue'
import ChooseCustomer from '@/components/ChooseCustomer.vue'

export default {
  name: 'Categories',
  components: {
    Header,
    Footer,
    FooterMobile,
    HotlineMobileCard,
    ChooseCustomer
  },
  data () {
    return {}
  },
  computed: {
    banners () {
      return this.$store.state.banners
    },
    groupProduct () {
      return this.$store.state.product.groupProduct
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: false,
      headingText: '',
      back: false,
      search: true,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', true)
    this.$store.dispatch('product/getGrProductAll')
    this.$store.dispatch('getBanner')
  }
}
</script>

<style lang="scss">
.el-carousel__container {
  padding-bottom: 50%;
}
  .category__item {
      position: relative;
      border-radius: 15px;
      margin: 0 auto 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 100%;
      width: 100px;
      &::before {
        content: "";
        width: 100%;
        padding-bottom: 100%;
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 9;
        cursor: pointer;
        transition: 0.3s all;
        &:hover {
          opacity: 0.75;
        }
        &:active {
          opacity: 0.5;
        }
      }
  }

  .category__action {
      font-weight: 600;
      text-decoration: none;
      color: #003B35;
      margin-bottom: 12px;
      display: block;
      line-height: 18px;
      &:hover {
          text-decoration: none;
          color: #003B35;
          opacity: 0.75;
      }
      &:active, &:disabled {
          opacity: 0.5;
      }
      &:focus {
          outline: none;
      }
  }
</style>
